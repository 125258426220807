import React from "react";
import { connectReplacementProducts } from "../../connectors/connectReplacementProducts";
import InfoIcon from "../../icons/Info";
import ErrorIcon from "../../icons/Error";
import scss from "./RelatedProducts.module.scss";
import Translation from "../Translation";

export interface RelatedProductsProps {
  hits: any[];
}

const RelatedProducts: React.FC<RelatedProductsProps> = ({ hits }) => {
  if (hits.length === 0) return null;
  return (
    <div id="searchBanners">
      <div className={scss.RelatedBanner}>
        <div className={scss.iconBox}>
          <ErrorIcon height={35} width={35} fill="white"/>
        </div>
        <div className={scss.textBox}>
          <p className={scss.text}>
            <Translation keyName="relatedBannerText" />
          </p>
        </div>
      </div>
      <div className={scss.ContactBanner}>
        <div className={scss.headerBox}>
          <p>
            <Translation keyName="contactBannerHeader" />
          </p>
        </div>
        <div className={scss.textBox}>
          <div className={scss.iconBox}>
            <InfoIcon height={15} width={15} fill="#0575D6"/>
          </div>
          <p className={scss.text}>
            <Translation keyName="contactBannerText" />{" "}
            <a
              className={scss.link}
              href="https://www.arthrex.com/representation-finder"
              onClick={() => {
                window.ga("event", "rep-finder", {
                  event_category: "Link Clicks",
                });
              }}
            >
              <Translation keyName="arthrexRepresentative" />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default connectReplacementProducts(RelatedProducts);
