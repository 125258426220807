import React from "react";
import CurrentRefinements from "./CurrentRefinements/CurrentRefinements";
import Facets from "./Facets/Facets";
import TotalResults from "./TotalResults";
import { useScreenSize, breakpoints } from "../hooks";
import scss from "./SearchResults.module.scss";
import { useStateContext } from "../contexts";
import Hits from "./Hits";
import RelatedSearches from "./RelatedSearches/RelatedSearches";
import classNames from "classnames";
import AlgoliaClient from "./AlgoliaClient";
import { connectSearchBox } from "react-instantsearch-dom";

const VirtualSearchBox = connectSearchBox(() => null);

const SearchResults: React.FC = () => {
  const { urlState, currentSuggestions } = useStateContext();
  let [showMobileFacets, setShowMobileFacets] = React.useState(false);
  const isMobile = useScreenSize.lessThan(breakpoints.medium);

  return (
    <div
      id="searchResults"
      className={classNames("search-next container p-md-0", scss.SearchResults)}
    >
      <AlgoliaClient>
        <div className={scss.results}>
          <TotalResults />
        </div>

        <div className="row">
          <Facets
            showMobileFacets={showMobileFacets && isMobile}
            setShowMobileFacets={setShowMobileFacets}
          />
          <div className="col-xl-9 col-lg-8 col-md-12">
            <CurrentRefinements
              isMobile={isMobile}
              setShowMobileFacets={setShowMobileFacets}
              transformItems={items =>
                items.filter((item: any) => item.attribute !== "translation")
              }
            />
            <VirtualSearchBox defaultRefinement={urlState.query} />

            <Hits query={urlState.query} />
          </div>
        </div>

        <RelatedSearches suggestions={currentSuggestions} />
      </AlgoliaClient>
    </div>
  );
};

export default SearchResults;
