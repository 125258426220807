import { ApiKeys } from "./restrictedApiKeys";

const searchNextKey = "search-next";
const searchHistoryKey = "search-history";

export interface SearchNext {
  accessToken: string;
  country: string;
  domain: string;
  index: string;
  keys: ApiKeys;
  language: string;
  userGroups: any;
}

export interface SearchHistoryItem {
  query: string;
  timestamp: number;
}

const getSearchNext = (): SearchNext =>
  JSON.parse(localStorage.getItem(searchNextKey) || "{}");

const getSearchHistory = (): SearchHistoryItem[] =>
  JSON.parse(localStorage.getItem(searchHistoryKey) || "[]");

const setSearchNext = (searchNext: SearchNext) =>
  localStorage.setItem(searchNextKey, JSON.stringify(searchNext));

const addSearchToHistory = (query: string) => {
  if (query === "") return;

  query = query.toLowerCase();

  let history = getSearchHistory();

  const searchIndex = history.findIndex(
    (search: SearchHistoryItem) => search.query === query
  );

  const search: SearchHistoryItem = {
    query,
    timestamp: Date.now(),
  };

  if (searchIndex < 0) {
    history.push(search);
  } else {
    history[searchIndex] = search;
  }

  localStorage.setItem(searchHistoryKey, JSON.stringify(history));
};

const getSortedHistory = (max: number) => {
  let history = getSearchHistory();

  history.sort(
    (x: SearchHistoryItem, y: SearchHistoryItem) => y.timestamp - x.timestamp
  );

  return history.slice(0, max);
};

export { getSearchNext, setSearchNext, addSearchToHistory, getSortedHistory };
