import React from "react";
import { connectStateResults } from "react-instantsearch-dom";
import { useStateContext } from "../../contexts";
import SearchHistory from "../EmptyStateColumn/SearchHistory/SearchHistory";
import TrendingSearches from "../EmptyStateColumn//TrendingSearches/TrendingSearches";

export interface StateResultsProps {
  searchResults: any;
  searching: boolean;
}

const StateResults: React.FC<StateResultsProps> = ({
  searchResults,
  searching,
}) => {
  const total = searchResults && searchResults.nbHits;
  const { submitQuery } = useStateContext();

  if (searching || total !== 0) {
    return null;
  }

  return (
    <div>
      <SearchHistory onSearch={submitQuery} />
      <TrendingSearches onSearch={submitQuery} />
    </div>
  );
};

export default connectStateResults(StateResults);
