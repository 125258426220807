import React from "react";
import {
  Index,
  Configure,
  connectRefinementList,
} from "react-instantsearch-dom";
import KeyMatchCards from "./KeyMatchCards/KeyMatchCards";
import ResourceHits from "./ResourceHits/ResourceHits";
import FeaturedResults from "./FeaturedResults/FeaturedResults";
import StateResults from "./StateResults/StateResults";
import Edfu from "./Edfu/Edfu";
import { useSearchContext } from "../contexts";
import { connectViewAll } from "../connectors";
import FeaturedResultsHeader from "./FeaturedResults/FeaturedResultsHeader/FeaturedResultsHeader";
import { getSearchNext } from "../utils";
import RelatedProducts from "./RelatedProducts/RelatedProducts";

export interface HitsProps {
  query: string;
  viewAll: string;
}

const VirtualRefinementList = connectRefinementList(() => null);

let date = new Date();
let currentDate = date.getTime() / 1000;
let upcomingDate =
  new Date(date.setMonth(date.getMonth() + 12)).getTime() / 1000;

const Hits: React.FC<HitsProps> = ({ query, viewAll }) => {
  const { index } = useSearchContext();
  const { userGroups } = getSearchNext();

  // index to be used by arthrex employees when logged in
  const replicaIndex: string = index.concat("-replica");

  const groups = userGroups?.length > 0 ? userGroups : ["public"];
  // true or false depending on if the employee token is in local storage
  const employeeLoggedIn: boolean = groups.includes("employee");

  if (viewAll) {
    return (
      <>
        <VirtualRefinementList
          attribute="asset_groups"
          defaultRefinement={[viewAll]}
        />
        <Index
          indexName={employeeLoggedIn ? replicaIndex : index}
          indexId="resources"
        >
          <ResourceHits />
        </Index>
      </>
    );
  }

  return (
    <>
      <Index
        indexName={employeeLoggedIn ? replicaIndex : index}
        indexId="replacement_products"
      >
        <Configure
          facetingAfterDistinct
          restrictSearchableAttributes="replaces_product_numbers"
        />
        <RelatedProducts />
      </Index>
      <div data-testid="promoted-results">
        <Index
          indexName={employeeLoggedIn ? replicaIndex : index}
          indexId="key_matches"
        >
          <Configure
            facetingAfterDistinct
            hitsPerPage={3}
            restrictSearchableAttributes="keys"
          />
          <KeyMatchCards />
        </Index>

        <Edfu q={query} />

        <Index indexName={`${index}-events-sorted`} indexId="events">
          <Configure
            facetingAfterDistinct
            hitsPerPage={3}
            filters={`content_type:event AND starts_timestamp:${currentDate} TO ${upcomingDate}`}
          />
          <FeaturedResults>
            <FeaturedResultsHeader
              translationKey="upcomingEvents"
              type="events"
              hideViewAll={true}
            />
          </FeaturedResults>
        </Index>
      </div>

      <Index
        indexName={employeeLoggedIn ? replicaIndex : index}
        indexId="resources"
      >
        <ResourceHits />
      </Index>

      <StateResults />
    </>
  );
};

export default connectViewAll(Hits);
