import React, { useState } from "react";
import { Configure, connectHits } from "react-instantsearch-dom";
import classNames from "classnames";
import InsightsLink from "../InsightsLink/InsightsLink";
import ImageLink from "../ImageLink/ImageLink";
import TotalHits from "./TotalHits";
import GoogleAnalytics from "./GoogleAnalytics";
import Description from "../Description/Description";
import scss from "./ResourceHits.module.scss";
import Translation from "../Translation";

const pageLength = 10;

export interface ResourceHitsProps {
  hits: any[];
}

const ResourceHits: React.FC<ResourceHitsProps> = ({ hits }) => {
  const [length, setLength] = useState(pageLength);
  const [totalHits, setTotalHits] = useState(0);

  return (
    <div
      hidden={hits.length === 0}
      data-testid="general-resource-list"
      className={classNames(scss.ResourceHits, "hits")}
    >
      <Configure offset={0} length={length} />
      <TotalHits setTotalHits={setTotalHits} />
      {window.ga && <GoogleAnalytics />}

      {hits.map((hit, i) => (
        <div
          className={classNames("row", scss.hit)}
          data-testid="general-resource-result"
          key={hit.objectID}
        >
          <div className={classNames("col-sm-4", "col-12")}>
            <ImageLink hit={hit} type="resource" position={i + 1} />
          </div>

          <div className="col-sm-8 col-12">
            <h4 className={scss.heading}>
              <InsightsLink name="resource" position={i + 1} hit={hit} />
            </h4>
            <Description index={i} hit={hit} showDate={true} />
          </div>
        </div>
      ))}
      {totalHits > length && (
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <button
              className={classNames("sn-button", scss.showMore, "hand-cursor")}
              onClick={() => setLength(length + pageLength)}
              id="showMoreResultsButton"
            >
              <Translation keyName="showMoreResults" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default connectHits(ResourceHits);
