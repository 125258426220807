import { createConnector } from "react-instantsearch-dom";
import { getResults } from "./getResults";

export const connectViewAll = createConnector({
  displayName: "ViewAll",
  getProvidedProps(props: any, searchState, searchResults) {
    const viewAll = searchState.viewAll || "";
    const nbHits = getResults(searchResults, props)?.nbHits || 0;

    return { viewAll, nbHits };
  },
  refine(_, searchState, nextRefinement) {
    return {
      ...searchState,
      viewAll: nextRefinement,
    };
  },
  cleanUp(_, searchState) {
    const { viewAll, ...nextSearchState } = searchState;

    return nextSearchState;
  },
});
