import React from "react";
import { i18n } from "../utils";
import { useSearchContext } from "../contexts";

export interface TranslationProps {
  keyName: string;
  params?: any;
}

const Translation: React.FC<TranslationProps> = ({ keyName, params }) => {
  const { language } = useSearchContext();

  return (
    <span
      style={{ color: "inherit" }}
      dangerouslySetInnerHTML={{
        __html: i18n.t(keyName, {
          lng: language,
          ...params,
        }),
      }}
    ></span>
  );
};

export default Translation;
