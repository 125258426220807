import { createConnector } from "react-instantsearch-dom";
import { addSearchToHistory } from "../utils";

export const connectQuery = createConnector({
  displayName: "Query",
  getProvidedProps(_, searchState) {
    const currentRefinement = searchState.query || "";

    return { currentRefinement };
  },
  refine(_, searchState, nextRefinement) {
    addSearchToHistory(nextRefinement);

    return {
      ...searchState,
      query: nextRefinement,
    };
  },
  cleanUp(_, searchState) {
    const { query, ...nextSearchState } = searchState;

    return nextSearchState;
  },
});
