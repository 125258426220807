import React, { useRef } from "react";
import classNames from "classnames";
import { algoliaInsights, getFallbackImage } from "../../utils";
import { useAuthContext, useSearchContext } from "../../contexts";
import scss from "./ImageLink.module.scss";
import { getImageUrl } from "../../utils/get-image-url";
import { appendLocaleToURL } from "../../utils/urlState";

export interface ImageLinkProps {
  hit: any;
  type: string;
  position: number;
}

const ImageLink: React.FC<ImageLinkProps> = ({ hit, type, position }) => {
  const { index, userId, userHasOptedOut, language } = useSearchContext();
  const { apiKey } = useAuthContext();
  const insights = algoliaInsights(apiKey, userId, userHasOptedOut);
  const {
    document_id,
    product_number,
    url,
    image_url,
    content_type,
    file_type,
    objectID,
    __queryID,
  } = hit;
  const ref = useRef<HTMLAnchorElement>(null);

  return (
    <a
      ref={ref}
      href={appendLocaleToURL(content_type, url, language)}
      className={classNames(
        scss.ImageLink,
        scss[type],
        { [scss[content_type]]: image_url },
        { [scss[file_type]]: image_url }
      )}
      onClick={() =>
        insights.sendEvent({
          eventType: "click",
          eventName: `select ${type} image`,
          objectIDs: [objectID],
          queryID: __queryID,
          index,
          positions: [position],
        })
      }
    >
      <img
        onError={({ target }) => {
          const element = target as HTMLImageElement;
          element.src = getFallbackImage(content_type, file_type);

          ref.current?.classList.remove(scss[content_type], scss[file_type]);
          element.classList.add(scss.imageNotFound);
        }}
        className={classNames(scss.thumbnail, {
          [scss.imageNotFound]: !image_url,
        })}
        src={
          getImageUrl(image_url, content_type, document_id, product_number) ||
          getFallbackImage(content_type, file_type)
        }
        alt={content_type}
      />
      {file_type === "vid" && <i className={scss.videoIconOverlay} />}
    </a>
  );
};

export default ImageLink;
