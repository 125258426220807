import React from "react";
import scss from "./FeaturedResultsHeader.module.scss";
import Translation from "../../Translation";
import { connectViewAll } from "../../../connectors";

export interface FeaturedResultsHeaderProps {
  nbHits: number;
  translationKey: string;
  hideViewAll?: boolean;
  type: string;
  refine: (viewAll: string) => void;
}

const FeaturedResultsHeader: React.FC<FeaturedResultsHeaderProps> = ({
  nbHits,
  translationKey,
  hideViewAll,
  refine,
  type,
}) => {
  return (
    <div className={scss.header}>
      <h3 className={scss.heading}>
        <Translation keyName={translationKey} />{" "}
        <span hidden={hideViewAll} className={scss.count}>
          ({nbHits})
        </span>
      </h3>
      {!hideViewAll && nbHits >= 3 && (
        <button
          className="linkButton"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            refine(type);
          }}
        >
          <Translation keyName="viewAll" />
        </button>
      )}
    </div>
  );
};

export default connectViewAll(FeaturedResultsHeader);
