const getLocale = (items: any[]) =>
  items.map((item) => ({
    ...item,
    label: locales.get(item.label),
  }));

const locales = new Map([
  ["ar", "Arabic"],
  ["at", "Austria"],
  ["bg", "Bulgarian"],
  ["bs", "Bosnian"],
  ["ch", "Switzerland"],
  ["cs", "Czech"],
  ["da", "Danish"],
  ["de", "Deutsch"],
  ["el", "ελληνικά"],
  ["en", "English"],
  ["es", "Español"],
  ["et", "Estonian"],
  ["fi", "Suomi"],
  ["fr", "Français"],
  ["gb", "United Kingdom"],
  ["hr", "Croatian"],
  ["hu", "Hungarian"],
  ["it", "Italiano"],
  ["ja", "日本語"],
  ["ko", "한국의"],
  ["lt", "Lithuanian"],
  ["lv", "Latvian"],
  ["mt", "Maltese"],
  ["nl", "Nederlandse"],
  ["no", "Norske"],
  ["pl", "Polski"],
  ["pt", "Português"],
  ["pt_BR", "Português do Brasil"],
  ["ro", "Român"],
  ["ru", "русский"],
  ["sk", "Slovak"],
  ["sl", "Slovenija"],
  ["sr", "Serbian"],
  ["sv", "Svenska"],
  ["tr", "Türk"],
  ["uk", "Українська"],
  ["vi", "Tiếng Việt"],
  ["zh", "中国的"],
]);

export { locales, getLocale };
