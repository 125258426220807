import React from "react";
import { TrendingIcon } from "../../../icons";
import { algoliaInsights } from "../../../utils";
import { useScreenSize, breakpoints, useSuggestions } from "../../../hooks";
import { useSearchContext, useAuthContext } from "../../../contexts";
import EmptyStateColumn from "../EmptyStateColumn";

export interface TrendingSearchesProps {
  onSearch: (query: string) => void;
  className?: string;
}

const TrendingSearches: React.FC<TrendingSearchesProps> = ({
  onSearch,
  className,
}) => {
  const { index, userId, userHasOptedOut } = useSearchContext();
  const { apiKey } = useAuthContext();
  const insights = algoliaInsights(apiKey, userId, userHasOptedOut);
  const maxResults = useScreenSize.lessThan(breakpoints.small) ? 3 : 5;
  const hits = useSuggestions("");

  return (
    <EmptyStateColumn
      Icon={TrendingIcon}
      translationKey="trendingSearches"
      className={className}
    >
      <div id="trending-searches">
        {hits?.slice(0, maxResults).map((hit) => (
          <div
            onClick={() => {
              insights
                .sendEvent({
                  eventType: "click",
                  eventName: `select trending search`,
                  objectIDs: [hit.objectID],
                  index: `${index}_query_suggestions`,
                })
                ?.finally(() => {
                  onSearch(hit.query);
                });
            }}
            className="blue hand-cursor text-decoration-none py-2"
            key={hit.objectID}
          >
            {hit.query}
          </div>
        ))}
      </div>
    </EmptyStateColumn>
  );
};

export default TrendingSearches;
