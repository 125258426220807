import React from "react";
import classNames from "classnames";
import scss from "./RelatedSearches.module.scss";
import { connectQuery } from "../../connectors";
import { useAuthContext, useSearchContext } from "../../contexts";
import Translation from "../Translation";
import { algoliaInsights } from "../../utils";

export interface RelatedSearchesProps {
  suggestions: any[];
}

export interface RelatedSearchLinkProps {
  objectID: string;
  query: string;
  refine: (query: string) => void;
}

const RelatedSearchLink = connectQuery(
  ({ refine, objectID, query }: RelatedSearchLinkProps) => {
    const { index, userId, userHasOptedOut } = useSearchContext();
    const { apiKey } = useAuthContext();
    const insights = algoliaInsights(apiKey, userId, userHasOptedOut);

    return (
      <div
        className={classNames(scss.link, "blue")}
        data-testid="related-search-link"
        onClick={() => {
          refine(query);
          window.scrollTo({ top: 0, behavior: "smooth" });
          insights.sendEvent({
            eventType: "click",
            eventName: `select related search`,
            objectIDs: [objectID],
            index: `${index}_query_suggestions`,
          });
        }}
      >
        {query}
      </div>
    );
  }
);

const RelatedSearches: React.FC<RelatedSearchesProps> = ({ suggestions }) => {
  if (!suggestions || suggestions.length === 0) {
    return null;
  }

  let columns = [];
  columns.push(suggestions.slice(0, 3));
  columns.push(suggestions.slice(3, 6));
  columns.push(suggestions.slice(6, 9));

  return (
    <div className={classNames(scss.RelatedSearches, "row")} data-testid="related-searches">
      <div className="offset-xl-3 offset-lg-4 col-xl-9 col-lg-8 col-md-12">
        <h3 className={scss.heading}>
          <Translation keyName="alsoSearchFor" />
        </h3>
        <div className="row" id="related-searches-row">
          {columns.map((column, i) => (
            <div key={i} className="col-md-4 col-sm-12">
              {column.map((hit) => (
                <RelatedSearchLink key={hit.objectID} {...hit} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RelatedSearches;
