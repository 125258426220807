import React from "react";
import { connectViewAll } from "../../../connectors";
import { CloseIcon } from "../../../icons";
import { locales } from "../../../utils";
import scss from "./Pill.module.scss";

export interface PillProps {
  refine: (viewAll: any) => void;
  setCurrentRefinements: (value: any) => void;
  attribute: string;
  label: string;
  value: any;
}

const transformLabel = (attribute: string, label: string) => {
  switch (attribute) {
    case "translation":
      return locales.get(label);
    case "content_type":
      return "events";
    case "type":
      return "webpages";
    default:
      return label;
  }
};

const Pill: React.FC<PillProps> = ({
  refine,
  attribute,
  value,
  label,
  setCurrentRefinements,
}) => {
  return (
    <div
      className={scss.Pill}
      data-testid="refinement-pill"
      onClick={() => {
        if (attribute === "asset_groups") {
          refine(undefined);
        } else {
          setCurrentRefinements(value);
        }
      }}
    >
      {transformLabel(attribute, label)}
      <span className={scss.icon}>
        <CloseIcon />
      </span>
    </div>
  );
};

export default connectViewAll(Pill);
