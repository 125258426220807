import React from "react";
import { connectStateResults } from "react-instantsearch-dom";

export interface CheckboxProps {
  item: any;
  refine: (value: any) => void;
  isSearchStalled: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  item,
  refine,
  isSearchStalled,
}) => {
  return (
    <input
      disabled={isSearchStalled}
      type="checkbox"
      checked={item.isRefined}
      onChange={() => refine(item.value)}
      data-testid={`${item.label} checkbox`.replaceAll(" ", "-")}
    />
  );
};

export const StateCheckbox = connectStateResults(Checkbox);
