import React from "react";
import classNames from "classnames";
import scss from "./EmptyStateColumn.module.scss";
import Translation from "../Translation";

export interface EmptyStateColumnProps {
  children: any;
  Icon: () => JSX.Element;
  translationKey: string;
  className?: string;
}

const EmptyStateColumn: React.FC<EmptyStateColumnProps> = ({
  children,
  Icon,
  translationKey,
  className,
}) => (
  <div className={className}>
    <div className={classNames(scss.columnHeader, "d-flex align-items-center")}>
      <div className={scss.columnIcon}>
        <Icon />
      </div>
      <h4 className={classNames(scss.columnHeading, "text-uppercase")}>
        <Translation keyName={translationKey} />
      </h4>
    </div>
    <div className={scss.columnIconChildren}>{children}</div>
  </div>
);

export default EmptyStateColumn;
