import { environment } from "../config/environment";

export const getFallbackImage = (contentType: string, fileType: string) => {
  let type = "";

  switch (contentType) {
    case "asset":
      if (fileType === "pdf") {
        type = "document";
      } else if (fileType === "vid") {
        type = "video";
      } else if (fileType === "ebk") {
        type = "ebook";
      } else if (fileType === "aud") {
        type = "audio";
      } else {
        type = "asset";
      }

      break;
    case "salesrep":
      type = "sales-rep";
      break;
    case "taxonomy":
      type = "webpage";
      break;
    case "product":
      type = "products";
      break;
    case "webpage":
    case "event":
    case "syndication":
    case "distributor":
      type = contentType;
      break;
    default:
      type = "asset";
  }

  return `${environment.CF_DISTRIBUTION}/assets/thumbnail-default-${type}@2x.png`;
};
