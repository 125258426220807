import React from "react";

const Clock = () => (
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M11.99 2C17.52 2 22 6.48 22 12s-4.48 10-10.01 10C6.47 22 2 17.52 2 12S6.47 2 11.99 2zM12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm.5 3v5.25l4.5 2.67-.75 1.23L11 13V7h1.5z"
      fill="#222426"
      fillRule="evenodd"
    />
  </svg>
);

export default Clock;
