import React from "react";
import Translation from "../Translation";
import scss from "./Edfu.module.scss";

export interface EdfuProps {
  q: string;
}

const Edfu: React.FC<EdfuProps> = ({ q }) => {
  if (!q) {
    return null;
  }

  var lowerQuery = q.toLowerCase();

  var displayInstructions =
    doesContain("ifu") ||
    doesContain("ifus") ||
    doesContain("edfu") ||
    doesContain("dfu") ||
    doesContain("user's guide") ||
    doesContain("user guide") ||
    doesContain("manual") ||
    doesContain("system user guide") ||
    doesContain("quick start") ||
    doesContain("set-up guide") ||
    doesContain("950-");

  function doesContain(s: string) {
    return lowerQuery.startsWith(s) || lowerQuery.endsWith(s);
  }

  return (
    <div>
      {displayInstructions && (
        <div id="eDFU-area" className={scss.edfuCard}>
          <img src="https://www.arthrex.com/images/img_edfu_illustration.png"></img>
          <p>
            <Translation keyName="edfuInstructions" />
          </p>

          <a href="https://www.eifu.online/arx" target="_blank">
            <button>
              <Translation keyName="accessEdfuSite" />
            </button>
          </a>
        </div>
      )}
    </div>
  );
};

export default Edfu;
