import React from "react";
import { ClockIcon } from "../../../icons";
import { useScreenSize, breakpoints } from "../../../hooks";
import { getSortedHistory } from "../../../utils";
import EmptyStateColumn from "../EmptyStateColumn";

export interface SearchHistoryProps {
  onSearch: (query: string) => void;
  className?: string;
}

const SearchHistory: React.FC<SearchHistoryProps> = ({
  onSearch,
  className,
}) => {
  const maxResults = useScreenSize.lessThan(breakpoints.small) ? 3 : 5;
  const searchHistory = getSortedHistory(maxResults);

  // Hide component if there is no search history in local storage
  if (searchHistory.length <= 0) {
    return null;
  }

  return (
    <EmptyStateColumn
      Icon={ClockIcon}
      translationKey="searchHistory"
      className={className}
    >
      <div id="search-history">
        {searchHistory.map((search: any) => (
          <div
            key={search.query}
            className="blue hand-cursor text-decoration-none py-2"
            onClick={() => onSearch(search.query)}
          >
            {search.query}
          </div>
        ))}
      </div>
    </EmptyStateColumn>
  );
};

export default SearchHistory;
