import { Cloudinary } from '@cloudinary/url-gen';
import { environment } from '../config/environment';

/**
 * cloudinaryUrlGenerator creates an instance of @see Cloudinary that is used for transforming,
 * optimizing and creating URLs for Cloudinary images and videos.
 *
 * @link https://cloudinary.com/documentation/javascript_image_transformations
 */
export const cloudinaryUrlGenerator = new Cloudinary(
  environment.CLOUDINARY_CONFIG
);
