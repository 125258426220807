import React, { createContext, useEffect, useState } from "react";
import { getAnonymousJWT } from "../utils";
import { ApiKey, getRestrictedApiKeys } from "../utils/restrictedApiKeys";

export interface Auth {
  jwt: string;
  apiKey: ApiKey;
}

const AuthContext = createContext({} as Auth);

interface AuthProps {
  accessToken: string;
  domain: string;
  country: string;
  index: string;
  language: string;
}

const AuthProvider: React.FC<AuthProps> = ({
  children,
  accessToken,
  domain,
  country,
  index,
  language,
}) => {
  const [jwt, setJwt] = useState(accessToken);
  const [apiKey, setApiKey] = useState({} as ApiKey);

  useEffect(() => {
    if (!accessToken) {
      getAnonymousJWT().then((anonJWT) => {
        setJwt(anonJWT);
        getRestrictedApiKeys(
          anonJWT,
          domain,
          country,
          index,
          language
        ).then((keys) => setApiKey(keys.search));
      });
    } else {
      getRestrictedApiKeys(
        accessToken,
        domain,
        country,
        index,
        language
      ).then((keys) => setApiKey(keys.search));
    }
  }, [domain, country, index, language]);

  return (
    <AuthContext.Provider value={{ jwt, apiKey }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  return React.useContext(AuthContext);
};

export { AuthProvider, useAuthContext };
