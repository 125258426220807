import { useEffect, useState } from "react";
import { useSearchContext, useAuthContext } from "../contexts";
import { client } from "../utils";

function useSuggestions(query: string, searchAsYouType?: boolean) {
  const { domain, country, index, language } = useSearchContext();
  const { jwt } = useAuthContext();
  let [suggestions, setSuggestions] = useState([] as any[]);
  let debounceTimeout: NodeJS.Timeout;

  let api = client(jwt, domain, country, index, language);

  const fetchSuggestions = () => {
    api
      .suggestions(query)
      .then((data) => {
        setSuggestions(data.hits);
      })
      .catch(() => {
        setSuggestions([]);
      });
  };

  useEffect(() => {
    if (searchAsYouType) {
      if (query.length > 2) {
        debounceTimeout = setTimeout(() => fetchSuggestions(), 350);
      } else {
        // if query is less than 3 characters, make sure suggestions is reset
        setSuggestions([]);
      }
    } else {
      fetchSuggestions();
    }

    return () => clearTimeout(debounceTimeout);
  }, [query]);

  return suggestions;
}

export default useSuggestions;
