import { useEffect } from "react";

function useEventToggle(
  event: string,
  ref: React.RefObject<HTMLElement>,
  state: boolean,
  setState: (state: boolean) => void
) {
  useEffect(() => {
    const toggle = ({ target }: any) => {
      if (ref.current && !ref.current.contains(target)) {
        setState(false);
      }
    };

    if (state) {
      document.addEventListener(event, toggle, true);
    } else {
      document.removeEventListener(event, toggle, true);
    }

    return () => {
      document.removeEventListener(event, toggle, true);
    };
  }, [state]);
}

export default useEventToggle;
