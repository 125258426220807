import React, { useRef } from "react";
import classNames from "classnames";
import RefinementList from "./RefinementList/RefinementList";
import { CloseIcon } from "../../icons";
import ClearFilters from "./ClearFilters/ClearFilters";
import { getLocale } from "../../utils";
import { useEventToggle } from "../../hooks";
import scss from "./Facets.module.scss";
import { useSearchContext } from "../../contexts";
import Translation from "../Translation";

export interface FacetsProps {
  showMobileFacets: boolean;
  setShowMobileFacets: (showMobileFacets: boolean) => void;
}

const Facets: React.FC<FacetsProps> = ({
  showMobileFacets,
  setShowMobileFacets,
}) => {
  const { language } = useSearchContext();
  const ref = useRef(null);
  useEventToggle("mousedown", ref, showMobileFacets, setShowMobileFacets);

  let defaultTranslations = [];
  const portuguese = ["pt", "pt_BR"];

  if (portuguese.includes(language)) {
    defaultTranslations.push(...portuguese);
  } else {
    defaultTranslations.push(language);
  }

  if (language !== "en") {
    defaultTranslations.push("en");
  }

  return (
    <div className="col-xl-3 col-lg-4">
      <div
        className={classNames(scss.Facets, { [scss.show]: showMobileFacets })}
      >
        <div id="facet-menu" ref={ref} className={scss.FacetContainer}>
          {showMobileFacets && (
            <div className={scss.mobileHeader}>
              <h3 className={scss.mobileFilterHeading}>
                <Translation keyName="filter" />
              </h3>
              <span onClick={() => setShowMobileFacets(false)}>
                <CloseIcon />
              </span>
            </div>
          )}
          {showMobileFacets && (
            <ClearFilters
              className={classNames("sn-button", scss.clearFiltersButton)}
            />
          )}
          <RefinementList
            idName="area-of-interest-facets"
            attribute={`interest_area_labels.${language}`}
            translationKey="areaOfInterest"
          />
          <RefinementList
            idName="webpages-facets"
            attribute="type"
            translationKey="webpages"
            transformItems={(items) =>
              items
                .filter((item: any) => item.label === "webpage")
                .map((type: any) => ({
                  ...type,
                  label: "webpages",
                }))
            }
          />
          <RefinementList
            idName="surgical-technique-facets"
            attribute={`procedure_type_labels.${language}`}
            translationKey="surgicalTechnique"
          />
          <RefinementList
            idName="scientific-resources-facets"
            attribute={`science_type_labels.${language}`}
            translationKey="scientificResources"
          />
          <RefinementList
            idName="product-information-facets"
            attribute={`product_type_labels.${language}`}
            translationKey="productInformation"
          />
          <RefinementList
            idName="language-facets"
            attribute="translation"
            translationKey="filterLanguage"
            transformItems={(items) => getLocale(items)}
            defaultRefinement={defaultTranslations}
          />
          <RefinementList
            idName="events-facets"
            attribute="content_type"
            translationKey="filterEvents"
            transformItems={(items) =>
              items
                .filter((item: any) => item.label === "event")
                .map((event: any) => ({
                  ...event,
                  label: "events",
                }))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Facets;
