const anonymousUserIdKey = "algolia-anonymous-user-id";

const getAnonymousUserId = () => {
  const localUserId = localStorage.getItem(anonymousUserIdKey);

  if (localUserId) {
    return localUserId;
  }

  const userId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );

  const anonymousUserId = `anonymous-${userId}`;

  localStorage.setItem(anonymousUserIdKey, anonymousUserId);

  return anonymousUserId;
};

export { getAnonymousUserId };
