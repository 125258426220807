import React, { Fragment } from "react";
import { connectCurrentRefinements } from "react-instantsearch-dom";
import classNames from "classnames";
import scss from "./CurrentRefinements.module.scss";
import Pill from "./Pill/Pill";
import Translation from "../Translation";
import ClearFilters from "../Facets/ClearFilters/ClearFilters";

const count = (items: any[]) => {
  let total = items.length;

  if (total > 0) {
    const lengths = items.map((x) => x.items.length);
    total = lengths.reduce((x, y) => x + y, 0);
  }

  return total;
};

export interface CurrentRefinementsProps {
  items: any[];
  refine: (value: any) => void;
  setShowMobileFacets: (showMobileFacets: boolean) => void;
  isMobile: boolean;
}

export interface ClearAllProps {
  items: any[];
  searchState: any;
  refine: (state: any) => void;
}

const CurrentRefinements: React.FC<CurrentRefinementsProps> = ({
  items,
  refine,
  setShowMobileFacets,
  isMobile,
}) => {
  if (isMobile) {
    return (
      <div className={scss.CurrentRefinements}>
        <button
          onClick={() => setShowMobileFacets(true)}
          className={classNames("sn-button", scss.filtersButton, {
            [scss.hasFilters]: items.length > 0,
          })}
        >
          <span className={scss.buttonTitle}>
            <Translation keyName="filter" />
          </span>
          {items.length > 0 && <span>({count(items)})</span>}
        </button>
      </div>
    );
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <div id="currentRefinementPills" className={scss.CurrentRefinements}>
      {items.map((item) => (
        <Fragment key={item.label}>
          {item.items.map((nested: any) => (
            <Pill
              key={nested.label}
              attribute={item.attribute}
              value={nested.value}
              label={nested.label}
              setCurrentRefinements={refine}
            />
          ))}
        </Fragment>
      ))}
      {items.length > 0 && <ClearFilters className="linkButton" />}
    </div>
  );
};

export default connectCurrentRefinements(CurrentRefinements);
