import { useState, useEffect } from "react";

const useKeyPress = (targetKey: string, preventDefault: boolean) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = (e: any) => {
    if (e.key === targetKey) {
      setKeyPressed(true);
      if (preventDefault) {
        e.preventDefault();
      }
    }
  };

  const upHandler = (e: any) => {
    if (e.key === targetKey) {
      setKeyPressed(false);
      if (preventDefault) {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};
export { useKeyPress };
