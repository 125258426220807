import React from "react";
import classNames from "classnames";
import { useAuthContext, useSearchContext } from "../../contexts";
import { algoliaInsights } from "../../utils";
import { appendLocaleToURL } from "../../utils/urlState";

export interface InsightsLinkProps {
  hit: any;
  name: string;
  position: number;
  className?: string;
}

const InsightsLink: React.FC<InsightsLinkProps> = ({
  hit,
  name,
  position,
  className,
}) => {
  const { index, userId, userHasOptedOut, language } = useSearchContext();
  const { apiKey } = useAuthContext();
  const insights = algoliaInsights(apiKey, userId, userHasOptedOut);
  const { url, title, title_html, objectID, __queryID, content_type } = hit;

  /**
   * decodeHTML decodes any special characters in a string to html.
   *
   * @param {string} html
   * @return {*}  {*}
   */
  const decodeHTML = (html: string): any => {
    const e = document.createElement("div");
    e.innerHTML = html;
    return { __html: e.childNodes[0].nodeValue };
  };

  /**
   * renderTitle renders the appropriate title field for the hit.
   *
   * @return {*}
   */
  const renderTitle = () => {
    if (title_html) {
      return <div dangerouslySetInnerHTML={decodeHTML(title_html)} />;
    }

    // We don't expect special characters that need decoding in this title
    return <span dangerouslySetInnerHTML={{ __html: title }} />;
  };

  return (
    <a
      href={appendLocaleToURL(content_type, url, language)}
      className={classNames(className, "d-block")}
      onClick={() => {
        insights.sendEvent({
          eventType: "click",
          eventName: `select ${name}`,
          objectIDs: [objectID],
          queryID: __queryID,
          index,
          positions: [position],
        });
      }}
    >
      {renderTitle()}
    </a>
  );
};

export default InsightsLink;
