import React, { useEffect, useMemo } from "react";
import { Configure, InstantSearch } from "react-instantsearch-dom";
import { client, getSearchNext } from "../utils";
import { useSearchContext, useStateContext, useAuthContext } from "../contexts";
import algoliasearch from "algoliasearch";
import { environment } from "../config/environment";
import SearchHistory from "./EmptyStateColumn/SearchHistory/SearchHistory";
import TrendingSearches from "./EmptyStateColumn/TrendingSearches/TrendingSearches";

const AlgoliaClient: React.FC = ({ children }) => {
  const {
    language,
    domain,
    country,
    index,
    userId,
    userHasOptedOut,
    embargoed,
  } = useSearchContext();
  const { jwt, apiKey } = useAuthContext();
  const { urlState, onUrlStateChange, submitQuery } = useStateContext();
  const [searchClient, setSearchClient] = React.useState<any>(undefined);
  const { userGroups } = getSearchNext();

  /**
   * directQuery is true if it is made from the URL and not from inside search.
   * The directQuery value is used as an Algolia analytics tag to differentiate queries
   * made using the URL and those made through the Search components. An `organic: true`
   * property is added to the history state any time a query or refinement is made within search.
   */
  const directQuery = useMemo(() => {
    return !window.history.state?.organic;
  }, [urlState]);

  /**
   * isFiltered checks if any filters are applied to the search results through
   * refinementList and viewAll.
   *
   * @return {*} {boolean}
   */
  const isFiltered = (): boolean => {
    const { viewAll, refinementList } = urlState;

    if (!refinementList) {
      return false;
    }
    /**
     * When a refinement is selected and then deselected, an empty string is left.
     * This filters out empty strings from the filters array.
     */
    const filters = Object.values(refinementList).filter((x) => x);
    return filters.length > 0 || !!viewAll;
  };

  useEffect(() => {
    if (embargoed) {
      setSearchClient(client(jwt, domain, country, index, language).search());
    } else if (apiKey.token) {
      setSearchClient(algoliasearch(environment.APP_ID, apiKey.token));
    }
  }, [apiKey]);

  /**
   * getAnalyticsTags returns an array of all of the analytics tags used for
   * filtering analytics in Algolia.
   *
   * @return {*}  {string[]}
   */
  const getAnalyticsTags = (): string[] => {
    const analyticsTags = [
      `domain:${domain}`,
      `language:${language}`,
      `country:${country}`,
      `directquery:${directQuery}`,
    ];
    const filtered = isFiltered();
    const today = new Date();
    const weekend = today.getDay() === 6 || today.getDay() === 0;
    const weekday = today.toLocaleDateString("en-US", {
      weekday: "long",
    });

    analyticsTags.push(`weekend:${weekend}`);
    analyticsTags.push(`weekday:${weekday}`);
    userGroups?.map((group: string) => {
      analyticsTags.push(`usergroup:${group}`);
    });
    analyticsTags.push(`filtered:${filtered}`);

    return analyticsTags;
  };

  if (!searchClient) {
    return null;
  }

  if (!urlState.query) {
    return (
      <div className={"container p-md-0"}>
        <div className="row justify-content-center py-5">
          <SearchHistory
            onSearch={submitQuery}
            className="col-md-4 col-sm-12"
          />
          <TrendingSearches
            onSearch={submitQuery}
            className="col-md-4 col-sm-12"
          />
        </div>
      </div>
    );
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={
        userGroups.includes("employee") ? index.concat("-replica") : index
      }
      searchState={urlState}
      onSearchStateChange={(state) => {
        onUrlStateChange(state);
      }}
    >
      <Configure
        facetingAfterDistinct
        clickAnalytics
        getRankingInfo
        userToken={userId}
        enablePersonalization={!userHasOptedOut}
        analyticsTags={getAnalyticsTags()}
      />
      {children}
    </InstantSearch>
  );
};

export default AlgoliaClient;
