import React, { Fragment, useEffect, useState } from "react";
import { useScreenSize, breakpoints, useSuggestions } from "../../hooks";
import {
  useSearchContext,
  useStateContext,
  useAuthContext,
} from "../../contexts";
import scss from "./Autocomplete.module.scss";
import TrendingSearches from "../../SearchResults/EmptyStateColumn/TrendingSearches/TrendingSearches";
import SearchHistory from "../../SearchResults/EmptyStateColumn/SearchHistory/SearchHistory";
import classNames from "classnames";
import { algoliaInsights } from "../../utils";
import { useKeyPress } from "../../hooks/useKeyPress";

export interface AutocompleteProps {
  onSearch: (hit: string) => void;
  showSuggestions: boolean;
  inputValue: string;
  query: string;
  setInputValue: (inputValue: string) => void;
  setInputValueQuietly: (inputValue: string) => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  inputValue,
  showSuggestions,
  query,
  onSearch,
  setInputValue,
  setInputValueQuietly,
}) => {
  const { index, domain, userId, userHasOptedOut } = useSearchContext();
  const { apiKey } = useAuthContext();
  const { setCurrentSuggestions } = useStateContext();
  const insights = algoliaInsights(apiKey, userId, userHasOptedOut);
  let maxResults = useScreenSize.lessThan(breakpoints.small) ? 5 : 10;
  const hits = useSuggestions(inputValue, true);
  const downPress = useKeyPress("ArrowDown", true);
  const upPress = useKeyPress("ArrowUp", true);
  const enterPress = useKeyPress("Enter", false);
  const [cursor, setCursor] = useState(0);
  const [isArrowing, setIsArrowing] = useState(false);

  useEffect(() => {
    if (hits.length && downPress) {
      if (!isArrowing) {
        setIsArrowing(true);
        setInputValueQuietly(hits[cursor].query);
      } else {
        let newCursorValue =
          cursor + 1 == hits.length ? hits.length - 1 : cursor + 1;
        setCursor(newCursorValue);
        if (hits[newCursorValue]?.query != query) {
          setInputValueQuietly(hits[newCursorValue].query);
        }
      }
    }
  }, [downPress]);
  useEffect(() => {
    if (hits.length && upPress && isArrowing) {
      if (cursor == 0) {
        setIsArrowing(false);
      } else {
        let newCursorValue = cursor - 1 < 0 ? 0 : cursor - 1;
        setCursor(newCursorValue);
        if (hits[newCursorValue]?.query != query) {
          setInputValueQuietly(hits[newCursorValue].query);
        }
      }
    }
  }, [upPress]);
  useEffect(() => {
    if (isArrowing) {
      setInputValue(hits[cursor].query);
      setIsArrowing(false);
      setCursor(0);
    }
  }, [enterPress]);

  useEffect(() => {
    if (inputValue === query) {
      setCurrentSuggestions(hits?.filter((item: any) => item.query !== query));
    }
  }, [query, hits]);

  if (showSuggestions && inputValue.length <= 2) {
    return (
      <div
        data-testid="emptystate"
        className={classNames(scss.Autocomplete, scss[domain])}
      >
        <div className={classNames(scss.container, "px-3 pb-3")}>
          <SearchHistory onSearch={onSearch} />
          <TrendingSearches onSearch={onSearch} />
        </div>
      </div>
    );
  }

  if (!showSuggestions) {
    return null;
  }

  return (
    <Fragment>
      <div
        id="autocomplete"
        className={classNames(scss.Autocomplete, scss[domain])}
      >
        <div className={scss.container} data-testid="suggestions">
          {hits?.slice(0, maxResults).map((hit, hitIndex) => (
            <div
              data-testid={`suggestion-${hitIndex}`}
              key={hit.query}
              className={classNames(
                scss.suggestion,
                isArrowing && hitIndex == cursor ? scss.active : null,
                isArrowing ? null : scss.hoverable
              )}
              onClick={() => {
                insights
                  .sendEvent({
                    eventType: "click",
                    eventName: "select suggestion",
                    objectIDs: [hit.objectID],
                    index: `${index}_query_suggestions`,
                  })
                  ?.finally(() => {
                    setInputValue(hit.query);
                    onSearch(hit.query);
                  });
              }}
            >
              <span
                className={scss.highlight}
                dangerouslySetInnerHTML={{
                  __html: hit._highlightResult.query.value,
                }}
              ></span>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Autocomplete;
