import { useEffect, useState } from "react";

const breakpoints = {
  extraSmall: 576,
  small: 768,
  medium: 992,
  large: 1200,
};

function useMedia(query: string) {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e: any) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  return matches;
}

const useScreenSize = {
  greaterThan(breakpoint: number) {
    return useMedia(`(min-width: ${breakpoint}px)`);
  },
  lessThan(breakpoint: number) {
    return useMedia(`(max-width: ${breakpoint - 0.02}px)`);
  },
};

export { useScreenSize, breakpoints };
