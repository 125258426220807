import React from "react";
import { Highlight } from "react-instantsearch-dom";
import { formatDate } from "../../utils";
import { useSearchContext } from "../../contexts";
import scss from "./Description.module.scss";

export interface DescriptionProps {
  index: any;
  hit: any;
  showDate: boolean;
}

const Description: React.FC<DescriptionProps> = ({ index, hit, showDate }) => {
  const { language } = useSearchContext();
  let i = index;
  let { asset_type_labels, contact_data, content_type } = hit;
  let date = formatDate(hit);
  const hasContactData = contact_data && Object.keys(contact_data).length > 0;

  const getAssetLabels = () => {
    const translatedLabels =
      asset_type_labels &&
      asset_type_labels
        .map((assetType: any) => assetType[language])
        .filter((label: string) => label)
        .join(", ");

    if (translatedLabels) {
      return translatedLabels;
    }

    return content_type === "taxonomy" ? "webpage" : content_type;
  };

  return (
    <div className={scss.Description}>
      <div className={scss.subtext}>
        <span data-testid={"resource-description-metadata-" + i}>
          <span className="text-capitalize">
            {content_type !== "salesrep" && getAssetLabels()}
          </span>
          {hit.product_number && (
            <>
              {" "}
              - <Highlight attribute="product_number" hit={hit} tagName="b" />
            </>
          )}{" "}
          {hit.literature_number && (
            <>
              {" "}
              -{" "}
              <Highlight attribute="literature_number" hit={hit} tagName="b" />
              {hit.version && <> {hit.version}</>}
            </>
          )}
          {showDate && date && <span> - {date}</span>}
        </span>
      </div>
      {hasContactData && (
        <div className={scss.contactData} data-testid="contact-details">
          <div data-testid="contact-title">
            {contact_data.title ? contact_data.title : contact_data.type}
            {contact_data.sales_forces && (
              <span>, {contact_data.sales_forces.join(", ")}</span>
            )}
          </div>
          <div className="mt-2 mb-2" data-testid="contact-regions">
            {contact_data.service_regions && (
              <div>{contact_data.service_regions.join(", ")}</div>
            )}
            {content_type === "salesrep" && <div>{contact_data.country}</div>}
          </div>
          <div className="mt-2 mb-2" data-testid="contact-address">
            <div>{contact_data.location}</div>
            <div>
              {[contact_data.address1, contact_data.address2].join(" ")}
            </div>
            <div>
              {[
                contact_data.locality,
                contact_data.region,
                contact_data.postal_code,
              ].join(" ")}
            </div>
          </div>

          {contact_data.mobile_number && (
            <div className="mt-2 mb-2" data-testid="contact-mobile">
              <div>{contact_data.mobile_number}</div>
            </div>
          )}
          {contact_data.phone_numbers && (
            <div className="mt-2 mb-2" data-testid="contact-numbers">
              {contact_data.phone_numbers.map((number: string) => (
                <div key={number}>{number}</div>
              ))}
            </div>
          )}
        </div>
      )}
      {!hasContactData && (
        <Highlight
          className="description"
          attribute="content"
          hit={hit}
          tagName="b"
        />
      )}
    </div>
  );
};

export default Description;
