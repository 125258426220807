import { stringify } from "qs";
import React, { createContext, useEffect, useState } from "react";
import { addSearchToHistory, parseParams, setUrlParams } from "../utils";

export interface State {
  urlState: any;
  onUrlStateChange: (state: any) => void;
  submitQuery: (state: string) => void;
  currentSuggestions: any[];
  setCurrentSuggestions: React.Dispatch<any[]>;
}

export interface StateProps {
  children: any;
  language: string;
}

const StateContext = createContext({} as State);

const StateProvider: React.FC<StateProps> = ({ children, language }) => {
  const [urlState, setUrlState] = useState(parseParams());
  const [currentSuggestions, setCurrentSuggestions] = useState<any[]>([]);

  const onUrlStateChange = (state: any) => {
    const { configure, indices, page, ...params } = state;
    if (stringify(params) !== stringify(urlState)) {
      setUrlParams(params, language);
      setUrlState(params);
    }
  };

  useEffect(() => {
    const popstate = () => {
      setUrlState(parseParams());
    };

    window.addEventListener("popstate", popstate);

    return () => {
      setUrlState({});
      window.removeEventListener("popstate", popstate);
    };
  }, []);

  const submitQuery = (query: string) => {
    addSearchToHistory(query);

    // Clear facets and remove view all anytime there is a search... SRCX-335
    const { refinementList, viewAll, ...newState } = urlState;

    onUrlStateChange({ ...newState, query });
  };

  return (
    <StateContext.Provider
      value={{
        urlState,
        onUrlStateChange,
        submitQuery,
        currentSuggestions,
        setCurrentSuggestions,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

const useStateContext = () => {
  return React.useContext(StateContext);
};

export { StateProvider, useStateContext };
