import React from "react";
import { connectStats } from "react-instantsearch-dom";
import Translation from "./Translation";

export interface TotalResultsProps {
  nbHits: number;
}

const TotalResults: React.FC<TotalResultsProps> = ({ nbHits }) => {
  return <Translation keyName="totalResults" params={{ total: nbHits }} />;
};

export default connectStats(TotalResults);
