import React from "react";
import { connectCurrentRefinements } from "react-instantsearch-dom";
import Translation from "../../Translation";
import { connectSearchState } from "../../../connectors";

export interface ClearFiltersProps {
  items: any[];
  searchState: any;
  refine: (state: any) => void;
  className?: string;
}

const ClearFilters = connectSearchState(
  ({ items, searchState, refine, className }: ClearFiltersProps) => {
    items = items.filter((item: any) => item.attribute !== "translation");

    return (
      <>
        {items.length > 0 && (
          <button
            className={className}
            onClick={() => {
              const { query } = searchState;
              refine({ query });
            }}
            disabled={!items.length}
          >
            <Translation keyName="clearFilters" />
          </button>
        )}
      </>
    );
  }
);

export default connectCurrentRefinements(ClearFilters);
