import React, { createContext } from "react";
import { environment } from "../config/environment";
import { getAnonymousUserId } from "../utils";
import { StateProvider } from "./StateProvider";
import "../stylesheets/index.scss";
import { AuthProvider } from "./AuthProvider";

export interface SearchProps {
  accessToken?: string;
  index?: string;
  userId?: string;
  language?: string;
  userHasOptedOut?: boolean;
  domain?: string;
  country?: string;
  embargoed?: boolean;
  className?: string;
}

export interface Search {
  accessToken: string;
  index: string;
  userId: string;
  language: string;
  userHasOptedOut: boolean;
  domain: string;
  country: string;
  embargoed: boolean;
}
const SearchContext = createContext({} as Search);

const SearchProvider: React.FC<SearchProps> = ({
  children,
  accessToken,
  index,
  userId,
  language,
  userHasOptedOut,
  domain,
  country,
  embargoed,
}) => {
  if (!userId && !userHasOptedOut) {
    userId = getAnonymousUserId();
  }

  return (
    <SearchContext.Provider
      value={{
        accessToken: accessToken!,
        index: index!,
        userId: userId!,
        language: language!,
        userHasOptedOut: userHasOptedOut!,
        domain: domain!,
        country: country!,
        embargoed: embargoed!,
      }}
    >
      <AuthProvider
        accessToken={accessToken!}
        domain={domain!}
        index={index!}
        country={country!}
        language={language!}
      >
        <StateProvider language={language!}>{children}</StateProvider>
      </AuthProvider>
    </SearchContext.Provider>
  );
};

SearchProvider.defaultProps = {
  index: environment.INDEX,
  country: "US",
  accessToken: "",
  language: "en",
  domain: "acom",
  userId: "",
  userHasOptedOut: false,
  embargoed: false,
};

const useSearchContext = () => {
  return React.useContext(SearchContext);
};

export { SearchProvider, useSearchContext };
