import axios from "axios";
import { environment } from "../config/environment";

const suggestionsEndpoint = `${environment.API_HOST}${environment.SUGGESTIONS_ENDPOINT}`;

const client = (
  accessToken: string,
  domain: string,
  country: string,
  index: string,
  language: string
) => {
  let headers = {};

  if (process.env.NODE_ENV === "e2e") {
    headers = {
      "X-Algolia-API-Key": "0ce08cd9879b4620a36a5cbf333ea179",
      "X-Algolia-Application-Id": environment.APP_ID,
    };
  } else {
    headers = {
      Authorization: `Bearer ${accessToken}`,
      "X-Client-ID": domain,
      "Geo-Country": country,
      "X-Arthrex-Index": index,
      "X-Arthrex-Locale": language,
    };
  }

  return {
    suggestions(query: string) {
      const encodedQuery = encodeURIComponent(query);

      return axios({
        url: `${suggestionsEndpoint}?query=${encodedQuery}&hitsPerPage=10&attributesToRetrieve=query`,
        method: "GET",
        headers,
      }).then((res) => res.data);
    },
    search() {
      return {
        search(requests: any) {
          requests = requests.map((req: any) => {
            req.params.facetFilters = JSON.stringify(req.params.facetFilters);
            req.params = new URLSearchParams(req.params).toString();
            return req;
          });

          return axios({
            url: `${environment.API_HOST}/multi/records`,
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              ...headers,
            },
            data: JSON.stringify({ requests }),
          }).then((res) => res.data);
        },
      };
    },
  };
};

export { client };
