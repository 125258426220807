import React from "react";

const ArrowDown = () => (
  <svg viewBox="0 0 24 24" width="1.75em" height="1.75em">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M7 10l5 5 5-5z" />
  </svg>
);

export default ArrowDown;
