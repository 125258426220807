import axios from "axios";
import { getSearchNext } from "./localStorage";
import { environment } from "../config/environment";

function isTokenValid(token: string) {
  if (!token) return false;

  let payload = token.split(".")[1];
  payload = window.atob(payload);

  return JSON.parse(payload).exp > Date.now() / 1000;
}

export const getAnonymousJWT = (): Promise<string> => {
  const searchNext = getSearchNext();
  const validToken = isTokenValid(searchNext.accessToken);

  if (validToken)
    return new Promise((resolve) => {
      resolve(searchNext.accessToken);
    });
  else {
    console.error("missing or invalid token. generating new one...");

    return axios({
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: "consumer_custom_id=test&grant_type=anonymous",
      url: `${environment.AUTH_SERVICE_HOST}/v1/authenticate`,
    })
      .then(({ data }) => data.access_token)
      .catch((err) => {
        console.error(err);
      });
  }
};
