import { createConnector } from "react-instantsearch-dom";
import { getResults } from "./getResults";

export const connectKeyMatches = createConnector({
  displayName: "KeyMatches",
  getProvidedProps(props: any, _, searchResults) {
    const results = getResults(searchResults, props);
    const keyMatches = results?.hits || [];
    const __queryID = results?.queryID || "";

    return { keyMatches, __queryID };
  },
  getSearchParameters(searchParameters, _, searchState) {
    searchParameters = searchParameters.setFacets(["keys"]);

    if (searchState.query) {
      searchParameters = searchParameters.addFacetRefinement(
        "keys",
        searchState.query
      );
    }

    return searchParameters;
  },
  cleanUp(_, searchState) {
    const { keyMatches, ...nextSearchState } = searchState;

    return nextSearchState;
  },
});
