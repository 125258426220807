import { createConnector } from "react-instantsearch-dom";
import { getResults } from "./getResults";

export const connectReplacementProducts = createConnector({
  displayName: "ReplacementProducts",
  getProvidedProps(props: any, _, searchResults) {
    const results = getResults(searchResults, props);
    const hits = results?.hits || [];

    return { hits };
  },
  getSearchParameters(searchParameters, _, searchState) {
    searchParameters = searchParameters.setFacets(["replaces_product_numbers"]);

    if (searchState.query) {
      searchParameters = searchParameters.addFacetRefinement(
        "replaces_product_numbers",
        searchState.query.toLocaleUpperCase().replaceAll(" ", "-")
      );
    }

    return searchParameters;
  },
  cleanUp(_, searchState) {
    const { hits, ...nextSearchState } = searchState;

    return nextSearchState;
  },
});
