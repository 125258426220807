import axios from "axios";
import { getSearchNext, setSearchNext } from "./localStorage";
import { environment } from "../config/environment";

export interface ApiKey {
  token: string;
}

export interface ApiKeys {
  search: ApiKey;
  suggestions: ApiKey;
}

const getRestrictedApiKeys = (
  accessToken: string,
  domain: string,
  country: string,
  index: string,
  language: string
): Promise<ApiKeys> => {
  const searchNext = getSearchNext();

  if (
    searchNext.accessToken === accessToken &&
    searchNext.domain === domain &&
    searchNext.country === country &&
    searchNext.index === index &&
    searchNext.language === language &&
    searchNext.userGroups?.length > 0
  ) {
    // Use Aloglia restricted API keys stored in local storage
    return new Promise((resolve) => {
      resolve(searchNext.keys);
    });
  }

  // Generate Algolia restricted API keys using JWT passed to Search
  let url = `${environment.API_HOST}/restricted-api-keys`;

  return axios({
    url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "X-Client-ID": domain,
      "Geo-Country": country,
      "X-Arthrex-Index": index,
      "X-Arthrex-Locale": language,
    },
  }).then(({ data }) => {
    // Use previous response if api_keys object is not present
    const keys = data.api_keys || data;

    setSearchNext({
      keys,
      userGroups: data.usergroups,
      accessToken,
      domain,
      country,
      index,
      language,
    });

    return keys;
  });
};

export { getRestrictedApiKeys };
