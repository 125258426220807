import React, { Fragment, useState } from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import classNames from "classnames";
import { ArrowUpIcon, ArrowDownIcon } from "../../../icons";
import Translation from "../../Translation";
import scss from "./RefinementList.module.scss";
import { StateCheckbox } from "../Checkbox/Checkbox";

const limit = 4;

export interface RefinementListProps {
  items: any[];
  refine: (value: any) => void;
  translationKey: string;
  idName?: string;
}

const RefinementList: React.FC<RefinementListProps> = ({
  items,
  translationKey,
  idName,
  refine,
}) => {
  let [extended, setExtended] = useState(false);
  let max = limit;

  if (items.length === limit + 1) {
    max = limit + 1;
  }

  const ShowMore = () => {
    if (items.length <= max) return null;

    return (
      <a
        id={"view-more-" + idName}
        className={classNames(
          scss.showMoreFacets,
          "blue",
          "d-flex",
          "align-items-center"
        )}
        onClick={() => setExtended(!extended)}
      >
        {extended ? (
          <Fragment>
            <Translation keyName="viewLess" />
            <ArrowUpIcon />
          </Fragment>
        ) : (
          <Fragment>
            <Translation keyName="viewMore" />
            <ArrowDownIcon />
          </Fragment>
        )}
      </a>
    );
  };

  if (items && items.length) {
    return (
      <div id={idName} className={scss.RefinementList}>
        <h5 className={classNames(scss.filterHeader, "text-uppercase")}>
          <Translation keyName={translationKey} />
        </h5>
        {items
          .slice(0, extended ? items.length : max)
          .map((item: any, index: number) => (
            <div key={`${item.label}_${index}`}>
              <label className={"d-inline-flex align-items-center"}>
                <StateCheckbox item={item} refine={refine} />
                <span data-testid="facet-label">
                  {item.label} ({item.count})
                </span>
              </label>
            </div>
          ))}
        <ShowMore />
      </div>
    );
  } else return null;
};

export default connectRefinementList(RefinementList);
