import { useEffect } from "react";
import { connectStateResults } from "react-instantsearch-dom";

declare global {
  interface Window {
    ga: any;
  }
}

export interface GoogleAnalyticsProps {
  searchResults: any;
}

const GoogleAnalytics: React.FC<GoogleAnalyticsProps> = ({ searchResults }) => {
  useEffect(() => {
    if (searchResults && searchResults.query && searchResults.nbHits == 0) {
      window.ga("send", {
        hitType: "event",
        eventCategory: "Search",
        eventAction: "no results",
        eventLabel: searchResults.query,
      });
    }
  }, [searchResults]);

  return null;
};

export default connectStateResults(GoogleAnalytics);
