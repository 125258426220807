import { parse, stringify } from "qs";
import { getSearchNext } from "./localStorage";

interface Refinements {
  [refinement: string]: string | string[];
}

const pathnames = new Map([
  ["en", "/search"],
  ["de", "/de/suche"],
  ["es", "/es/buscar"],
  ["pt", "/pt/search"],
]);

const parseParams = (): any => {
  const { q, viewAll, search, ...refinements } = parse(
    window.location.search.slice(1)
  );
  let refinementList: Refinements = {};

  for (const refinement in refinements) {
    refinementList[refinement] =
      refinementsDecoded(refinements[refinement]?.toString()) || [];
  }

  return { query: q || "", viewAll, refinementList };
};

const refinementsEncoded = (arr: []): string => {
  let strTemp = arr.join(";");
  return strTemp.replace(/,/g, "--");
};

const refinementsDecoded = (str: any): any => {
  let strDecoded = String(str).replace(/--/g, ",");
  return strDecoded.split(";");
};

const setUrlParams = (state: any, language: string) => {
  const { query, refinementList, viewAll } = state;
  const searchNext = getSearchNext();
  let url = pathnames.get(language) || "/search";
  let refinements: Refinements = {};

  for (const refinement in refinementList) {
    if (Array.isArray(refinementList[refinement])) {
      refinements[refinement] = refinementsEncoded(refinementList[refinement]);
    }
  }

  const urlParams = stringify({ q: query, viewAll, ...refinements });

  if (urlParams) {
    url = `${url}?${urlParams}`;
  }

  const samePath = window.location.pathname === pathnames.get(language);

  window.history.pushState({ ...state, organic: true }, document.title, url);

  // set organic search to true for nx/search-next integration
  if (searchNext.domain === "acom" && !window.localStorage.getItem("organic")) {
    window.localStorage.setItem("organic", JSON.stringify(true));
  }

  if (!samePath) {
    window.location.reload();
  }
};

/** 
  appendLocaleToURL will return a link with the locale query parameter appended to it if the content type is valid otherwise it will return the url as is
  
   * @param {string} contentType
   * @param {string} url
   * @param {string} locale
   * @return {*}  {string}
  * 
 */
  const appendLocaleToURL = (
    contentType: string,
    url: string,
    locale: string
  ): string => {
    const validContentTypes = ['webpage', 'taxonomy', 'asset'];
    if (validContentTypes.includes(contentType.toLowerCase())) {
      return addParamToUrl(url, 'locale', locale);
    }
    return url;
  };

/**
 * addParamToUrl will add a query parameter to a URL
 *
 * @param {string} url
 * @param {string} paramName
 * @param {string} paramValue
 * @returns {*} {string}
 */

function addParamToUrl(
  url: string,
  paramName: string,
  paramValue: string
): string {
  // Returns an empty string if URL is empty
  if (!url) {
    return '';
  }
  // Check if the URL already contains query parameters
  const hasQueryParams = url.includes('?');

  // Construct the parameter string to append to the URL
  const encodedParamName = encodeURIComponent(paramName);
  const encodedParamValue = encodeURIComponent(paramValue);
  const paramStr = `${encodedParamName}=${encodedParamValue}`;

  // Append the parameter string to the URL, either as a query parameter or as part of the path
  if (hasQueryParams) {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    if (params.has(paramName)) {
      params.set(paramName, paramValue);
    } else {
      params.append(paramName, paramValue);
    }
    urlObj.search = params.toString();
    return urlObj.toString();
  } else {
    return `${url}?${paramStr}`;
  }
}  

export { parseParams, setUrlParams, appendLocaleToURL, addParamToUrl };
