interface FormattedDate {
  month: number;
  day: number;
  year: number;
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getDate = (d: string): FormattedDate | null => {
  if (!d) return null;

  const date = new Date(d);

  return {
    month: date.getUTCMonth(),
    day: date.getUTCDate(),
    year: date.getUTCFullYear(),
  };
};

export default (hit: any) => {
  const { starts_at, ends_at, updated_at } = hit;
  let formattedDate = "";
  let date: FormattedDate | null;

  if (starts_at) {
    date = getDate(starts_at);

    formattedDate = date
      ? `${months[date.month]} ${date.day}, ${date.year}`
      : "";

    if (new Date(starts_at) < new Date(ends_at)) {
      date = getDate(ends_at);
      formattedDate += date
        ? ` to ${months[date.month]} ${date.day}, ${date.year}`
        : "";
    }
  } else if (updated_at) {
    date = getDate(updated_at);
    formattedDate = date ? `${date.month + 1}/${date.day}/${date.year}` : "";
  }

  return formattedDate;
};
