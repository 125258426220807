export interface DomainContent {
  [domain: string]: Content;
}

export interface Logo {
  url: string;
  height: number;
}

export interface Content {
  logo: Logo;
  displayName: string;
}

const domainContent: DomainContent = {
  acom: {
    logo: {
      url:
        "https://arthrex-images.s3.amazonaws.com/images/the-front-end/logo.png",
      height: 46,
    },
    displayName: "Arthrex",
  },
  vet_systems: {
    logo: {
      url:
        "https://www.arthrexvetsystems.com/images/arthrexvetsystemsweblogo@2x.png?106D31838CE65B3131B69788FB0F0E09",
      height: 84,
    },
    displayName: "Arthrex Vet Systems",
  },
};

export const getDomainContent = (key: string): Content =>
  domainContent[key] || domainContent.acom;
