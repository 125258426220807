import axios from "axios";
import { environment } from "../config/environment";
import { ApiKey } from "./restrictedApiKeys";

export interface Event {
  index: string;
  eventType: "click" | "conversion" | "view";
  eventName: string;
  objectIDs: string[];
  queryID?: string;
  positions?: number[];
}

export interface AlgoliaInsights {
  sendEvent(event: Event): Promise<any> | undefined;
}

const algoliaInsights = (
  apiKey: ApiKey,
  userToken: string,
  userHasOptedOut: boolean
): AlgoliaInsights => {
  const headers = {
    "X-Algolia-Api-Key": apiKey.token,
    "X-Algolia-Application-Id": environment.APP_ID,
    "Content-Type": "application/json",
  };

  return {
    sendEvent(event: Event) {
      if (userHasOptedOut) {
        return new Promise((resolve) => {
          resolve(undefined);
        });
      }

      return axios({
        url: "https://insights.algolia.io/1/events",
        method: "POST",
        headers,
        data: JSON.stringify({
          events: [
            {
              ...event,
              userToken,
            },
          ],
        }),
      }).then((res) => res.data);
    },
  };
};

export { algoliaInsights };
