import i18next from "i18next";

i18next.init({
  lng: "en",
  resources: {
    en: {
      translations: {
        search: "Search",
        searchPlaceholder:
          "Search for {{displayName}} techniques, products, events and more",
        edfuInstructions:
          "Instructions for Use/Directions for Use (eDFU) are now available on our new eDFU search tool.",
        accessEdfuSite: "Access the eDFU site",
        upcomingEvents: "Upcoming Events",
        products: "Products",
        searchHistory: "Search History",
        trendingSearches: "Trending Searches",
        resources: "Resources",
        filter: "Filter",
        totalResults: "Your search returned <b>{{total}}</b> results",
        clearFilters: "Clear All Filters",
        surgicalTechniqueResources: "Surgical Technique Resources",
        showMoreResults: "Show More Results",
        alsoSearchFor: "People also search for",
        scientificResources: "Scientific Resources",
        areaOfInterest: "Area of Interest",
        surgicalTechnique: "Surgical Technique",
        productInformation: "Product Information",
        filterLanguage: "Filter By Language",
        filterEvents: "Filter By Events",
        viewAll: "View All",
        viewLess: "View less",
        viewMore: "View More",
        clear: "Clear",
        enter: "Enter",
        esc: "Esc",
        revisionDate: "Revision date",
        relatedBannerText: "Here are results that are related to your query.",
        contactBannerHeader: "Need Help Finding Something?",
        contactBannerText:
          "For more information or help finding product information, please get in touch with an",
        arthrexRepresentative: "Arthrex representative.",
      },
    },
    es: {
      translations: {
        search: "Buscar",
        searchPlaceholder:
          "Busque productos, eventos, técnicas de {{displayName}} y más",
        edfuInstructions:
          "Las instrucciones de uso (eDFU), manuales del usuario, guías de referencia rápida y guías de instalación ya están disponibles en nuestra nueva herramienta de búsqueda eDFU.",
        accessEdfuSite: "Acceder al sitio eDFU",
        upcomingEvents: "Próximos Eventos",
        products: "Productos",
        searchHistory: "HISTORIAL DE BÚSQUEDA",
        trendingSearches: "BÚSQUEDAS FRECUENTES",
        resources: "Recursos",
        filter: "Filtrar",
        totalResults: "Se han encontrado <b>{{total}}</b> resultados",
        clearFilters: "Borrar todos los filtros",
        surgicalTechniqueResources: "Recursos para la técnica quirúrgica",
        showMoreResults: "Mostrar más resultados",
        alsoSearchFor: "Otros usuarios también buscaron",
        scientificResources: "Recursos científicos",
        areaOfInterest: "ÁREA DE INTERÉS",
        surgicalTechnique: "TÉCNICA QUIRÚRGICA",
        productInformation: "INFORMACIÓN DEL PRODUCTO",
        filterLanguage: "FILTRAR POR IDIOMA",
        filterEvents: "FILTRAR POR EVENTO",
        viewAll: "Ver todo",
        viewLess: "Ver menos",
        viewMore: "Ver más",
        clear: "Borrar",
        enter: "Intro",
        esc: "Esc",
        revisionDate: "Fecha de revisión",
        relatedBannerText: "Encuentre aquí los resultados relacionados con su búsqueda.",
        contactBannerHeader: "¿Necesita ayuda para encontrar algo?",
        contactBannerText:
          "Para obtener más información o ayuda en su búsqueda, comuníquese con un",
        arthrexRepresentative: "representante de Arthrex.",
      },
    },
    de: {
      translations: {
        search: "Suchen",
        searchPlaceholder:
          "Suchen nach {{displayName}} Operationstechniken, Produkten, Events und mehr",
        edfuInstructions:
          "Gebrauchsanleitungen / Gebrauchsanweisungen (eDFU), Benutzerhandbücher, Kurzanleitungen und Setup-Anleitungen stehen nun über unsere neue eDFU-Suchfunktion bereit.",
        accessEdfuSite: "Zugriff auf die eDFU-Site",
        upcomingEvents: "Demnächst stattfindende Events",
        products: "Produkte",
        searchHistory: "SUCHVERLAUF",
        trendingSearches: "AUCH HÄUFIG GESUCHT",
        resources: "Ressourcen",
        filter: "Filter",
        totalResults: "Ihre Suche ergab <b>{{total}}</b> Treffer",
        clearFilters: "Alle Filter zurücksetzen",
        surgicalTechniqueResources: "Operationsanleitungen Ressourcen",
        showMoreResults: "Mehr Ergebnisse anzeigen",
        alsoSearchFor: "Andere suchen auch nach",
        scientificResources: "Wissenschaftliche Ressourcen",
        areaOfInterest: "INTERESSENSGEBIET",
        surgicalTechnique: "OPERATIONSANLEITUNG",
        productInformation: "PRODUKTINFORMATIONEN",
        filterLanguage: "NACH SPRACHE FILTERN",
        filterEvents: "NACH EREIGNISSEN FILTERN",
        viewAll: "Alle anzeigen",
        viewLess: "Weniger anzeigen",
        viewMore: "Mehr anzeigen",
        clear: "Löschen",
        enter: "Eingeben",
        esc: "Abbrechen",
        revisionDate: "Revisionsdatum",
        relatedBannerText:
          "Hier sind die Ergebnisse, die mit Ihrer Suchanfrage verwandt sind.",
        contactBannerHeader: "Benötigen Sie Hilfe bei der Suche?",
        contactBannerText:
          "Wenn Sie weitere Informationen oder Hilfe bei der Suche benötigen, kontaktieren Sie einen",
        arthrexRepresentative: "Arthrex Ansprechpartner.",
      },
    },
    pt: {
      translations: {
        search: "Buscar",
        searchPlaceholder:
          "Pesquisar técnicas, produtos e eventos da {{displayName}} e outros assuntos",
        edfuInstructions:
          "As Instruções de Uso (eDFU), Manuais do Usuário, Guias de Referência Rápida e Guias de Instalação estão agora disponíveis em nossa nova ferramenta de busca de eDFU.",
        accessEdfuSite: "Acesso ao site de eDFU",
        upcomingEvents: "Próximos Eventos",
        products: "Produtos",
        searchHistory: "HISTÓRICO DE PESQUISA",
        trendingSearches: "PESQUISAS DE TENDÊNCIA",
        resources: "Recursos",
        filter: "Filtrar",
        totalResults: "Sua pesquisa retornou <b>{{total}}</b> resultados",
        clearFilters: "Limpar todos os filtros",
        surgicalTechniqueResources: "Recursos de Técnica Cirúrgica",
        showMoreResults: "Exibir Mais Resultados",
        alsoSearchFor: "As pessoas procuraram também por",
        scientificResources: "Recursos Científicos",
        areaOfInterest: "ÁREA DE INTERESSE",
        surgicalTechnique: "TÉCNICA CIRÚRGICA",
        productInformation: "INFORMAÇÕES SOBRE O PRODUTO",
        filterLanguage: "FILTRAR POR IDIOMA",
        filterEvents: "FILTRAR POR EVENTOS",
        viewAll: "Visualizar Tudo",
        viewLess: "Ver menos",
        viewMore: "Exibir Mais",
        clear: "Limpar",
        enter: "Enter",
        esc: "Esc",
        revisionDate: "Data de revisão",
        relatedBannerText: "Encontre aqui os resultados relacionados à sua pesquisa.",
        contactBannerHeader: "Precisa de ajuda para encontrar algo?",
        contactBannerText:
          "Para obter mais informações ou ajuda em sua pesquisa, entre em contato com um",
        arthrexRepresentative: "representante da Arthrex.",
      },
    },
  },
  fallbackLng: "en",
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
    format: function(value, format) {
      if (format === "uppercase") return value.toUpperCase();
      if (format === "lowercase") return value.toLowerCase();
      return value;
    },
  },
  react: {
    wait: true,
  },
});

export default i18next;
