import { useEffect } from "react";
import { connectStats } from "react-instantsearch-dom";

export interface TotalHitsProps {
  nbHits: number;
  setTotalHits: (nbHits: number) => void;
}

const TotalHits: React.FC<TotalHitsProps> = ({ nbHits, setTotalHits }) => {
  useEffect(() => {
    setTotalHits(nbHits);
  }, [nbHits]);

  return null;
};

export default connectStats(TotalHits);
