import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import { SearchProvider, SearchProps } from "./contexts";
import SearchBox from "./SearchBox/SearchBox";
import SearchResults from "./SearchResults/SearchResults";
import SearchResultsPortal from "./SearchResults/SearchResultsPortal";

const app = document.getElementById("search-next-app");

const Search: React.FC<SearchProps> = ({
  accessToken,
  index,
  userId,
  language,
  userHasOptedOut,
  domain,
  country,
  className,
  embargoed,
}) => (
  <SearchProvider
    accessToken={accessToken}
    index={index}
    userId={userId}
    language={language}
    userHasOptedOut={toBoolean(userHasOptedOut)}
    domain={domain}
    country={country}
    className={className}
    embargoed={toBoolean(embargoed)}
  >
    <SearchBox />
    <SearchResultsPortal />
  </SearchProvider>
);

const toBoolean = (prop: boolean | string | undefined): boolean => {
  if (typeof prop === "boolean") {
    return prop;
  } else if (typeof prop === "string") {
    return JSON.parse(prop);
  } else {
    return false;
  }
};

if (app) {
  const index = app.getAttribute("data-index") || undefined;
  const country = app.getAttribute("data-country") || undefined;
  const language = app.getAttribute("data-language") || undefined;
  const domain = app.getAttribute("data-domain") || undefined;
  const userId = app.getAttribute("data-user-id") || undefined;
  const userHasOptedOut =
    app.getAttribute("data-user-has-opted-out") || undefined;
  const accessToken = app.getAttribute("data-access-token") || undefined;
  const embargoed = app.getAttribute("data-embargoed") || undefined;

  ReactDOM.render(
    <Search
      index={index}
      country={country}
      language={language}
      domain={domain}
      userId={userId}
      userHasOptedOut={toBoolean(userHasOptedOut)}
      accessToken={accessToken}
      embargoed={toBoolean(embargoed)}
    />,
    app
  );
}

export default Search;

export { SearchProvider, SearchBox, SearchResults, SearchResultsPortal };
