import React from "react";
import { connectHits, Highlight } from "react-instantsearch-dom";
import classNames from "classnames";
import InsightsLink from "../InsightsLink/InsightsLink";
import ImageLink from "../ImageLink/ImageLink";
import { formatDate } from "../../utils";
import scss from "./FeaturedResults.module.scss";

export interface FeaturedResultsProps {
  hits: any[];
}

const FeaturedResults: React.FC<FeaturedResultsProps> = ({
  hits,
  children,
}) => {
  if (hits.length === 0) return null;
  else {
    let featureType = "unknown";
    if (hits[0].asset_groups && hits[0].asset_groups.length > 0) {
      featureType = hits[0].asset_groups[0]
    } else if (hits[0].content_type) {
      featureType = hits[0].content_type
    }

    return (
      <div className={classNames(scss.FeaturedResults, "hits")} data-testid={featureType + "-section"}>
        {children}
        <div className="row flex-nowrap overflow-auto">
          {hits.map((hit, i) => (
            <div
              className={classNames(scss.result, "col-sm-4", "col-6")}
              data-testid={featureType + "-card"}
              key={hit.objectID}
            >
              <ImageLink hit={hit} type="featured" position={i + 1} />
              <InsightsLink
                name="featured resource"
                className={scss.link}
                hit={hit}
                position={i + 1}
              />
              <div
                data-testid={"featured-result-metadata-info-" + i}
                className={scss.info}
              >
                {hit.authors && <div>{hit.authors.join(", ")}</div>}
                {hit.literature_number && (
                  <div>
                    <Highlight
                      attribute="literature_number"
                      hit={hit}
                      tagName="b"
                    />
                    {hit.version && <> {hit.version}</>}
                  </div>
                )}
                <div>
                  {hit.content_type !== "event" && "Revision date: "}
                  {formatDate(hit)}
                </div>
                {hit.contact_data &&
                  Object.keys(hit.contact_data).length > 0 && (
                    <div>
                      {[
                        hit.contact_data.locality,
                        hit.contact_data.region,
                        hit.contact_data.country,
                      ].join(", ")}
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default connectHits(FeaturedResults);
