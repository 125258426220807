import React from "react";
import ReactDOM from "react-dom";
import SearchResults from "./SearchResults";

const id = "search-next-results";
const element = document.getElementById(id);

const SearchResultsPortal: React.FC = () => {
  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(<SearchResults />, element);
};

export default SearchResultsPortal;
