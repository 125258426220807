import React from "react";
import classNames from "classnames";
import InsightsLink from "../InsightsLink/InsightsLink";
import ImageLink from "../ImageLink/ImageLink";
import Description from "../Description/Description";
import scss from "./KeyMatchCards.module.scss";
import { connectKeyMatches } from "../../connectors";

export interface KeyMatchCardsProps {
  keyMatches: any[];
  __queryID: string;
}

const KeyMatchCards: React.FC<KeyMatchCardsProps> = ({
  keyMatches,
  __queryID,
}) => {
  if (keyMatches.length === 0) {
    return null;
  }

  return (
    <div className={classNames(scss.KeyMatchCards, "hits")} id="keymatch-card">
      {keyMatches.map((hit, i) => (
        <div
          key={hit.objectID}
          data-testid="keymatch-card"
          className={classNames(scss.keyMatch, "row no-gutters")}
        >
          <div className="col-sm-4 col-12">
            <ImageLink hit={hit} type="keymatch" position={i + 1} />
          </div>

          <div className="col-sm-8 col-12">
            <div className={scss.info}>
              <h4 className={scss.heading} id="keymatch-header">
                <InsightsLink
                  name="keymatch"
                  className="blue"
                  hit={{ ...hit, __queryID }}
                  position={i + 1}
                />
              </h4>
              <Description
                index={i}
                hit={hit}
                showDate={hit.content_type === "event"}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default connectKeyMatches(KeyMatchCards);
