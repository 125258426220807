export const environment = {
  API_HOST: "https://search-next-api.arthrex.io",
  ARTHREX_NEXT_API_HOST: "https://www.arthrex.com",
  SUGGESTIONS_ENDPOINT: "/suggestions",
  AUTH_SERVICE_HOST: "https://auth.arthrex.io",
  INDEX: "prod",
  APP_ID: "0AICLE26XM",
  CF_DISTRIBUTION: "https://d3hnagzftqwanx.cloudfront.net",
  FEATURE_FLAGS: { SRCX_531: true },
  CLOUDINARY_CONFIG: {
    cloud: {
      cloudName: 'arthrex-production',
    },
    url: {
      secure: true,
      secureDistribution: 'cdn.arthrex.io',
      privateCdn: true,
      analytics: false,
    },
  },
  PROD_CLOUDINARY_DOMAIN: 'cdn.arthrex.io',
  DEV_CLOUDINARY_DOMAIN: '',
};
