import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { SearchIcon, BackspaceIcon, CloseIcon } from "../icons";
import Autocomplete from "./Autocomplete/Autocomplete";
import { useEventToggle } from "../hooks";
import { addSearchToHistory, i18n, getDomainContent } from "../utils";
import { useAuthContext, useSearchContext, useStateContext } from "../contexts";
import scss from "./SearchBox.module.scss";

const searchInputId = "searchInput";

const SearchBox: React.FC = () => {
  const { urlState, submitQuery } = useStateContext();
  const { query } = urlState;
  const { domain, language } = useSearchContext();
  const { jwt } = useAuthContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputValue, setInputValue] = useState(query);
  const placeholder = showSuggestions
    ? i18n.t("searchPlaceholder", {
        lng: language,
        displayName: getDomainContent(domain).displayName,
      })
    : "";
  useEventToggle(
    "mousedown",
    containerRef,
    showSuggestions,
    setShowSuggestions
  );
  useEventToggle("focus", containerRef, showSuggestions, setShowSuggestions);

  useEffect(() => {
    setInputValue(query);
  }, [query]);

  const onSearch = (value: string) => {
    submitQuery(value);
    setInputValue(value);
    setShowSuggestions(false);
    addSearchToHistory(value);
    inputRef.current?.blur();
  };

  return (
    <div
      className={classNames(scss.container, scss[domain], {
        [scss.open]: showSuggestions,
      })}
    >
      <div
        ref={containerRef}
        className={classNames("search-next", scss.SearchBox, scss[domain], {
          [scss.open]: showSuggestions,
        })}
      >
        <form
          className={classNames(scss.form, {
            [scss.open]: showSuggestions,
          })}
          onSubmit={e => {
            e.preventDefault();
            onSearch(inputValue);
          }}
        >
          <input
            ref={inputRef}
            autoComplete="off"
            autoCapitalize="off"
            spellCheck="false"
            className={classNames(scss.input, {
              [scss.suggestionsOpen]: showSuggestions,
            })}
            placeholder={placeholder}
            id={searchInputId}
            value={inputValue}
            onChange={({ target }) => {
              setInputValue(target.value);
            }}
            onFocus={() => setShowSuggestions(true)}
          />
          <div
            className={classNames(scss.searchInputButtons, {
              [scss.suggestionsOpen]: showSuggestions,
            })}
          >
            {inputValue && showSuggestions && (
              <button
                className={classNames(scss.inputButton, "hand-cursor")}
                type="button"
                onClick={() => {
                  setInputValue("");
                  document.getElementById(searchInputId)?.focus();
                }}
                id="clearButton"
              >
                <BackspaceIcon />
              </button>
            )}
            <button
              className={scss.inputButton + " hand-cursor"}
              type="submit"
              id="searchButton"
            >
              <SearchIcon />
            </button>
          </div>
        </form>

        <div
          className={scss.mobileSearch}
          data-testid="mobileSearchButton"
          onClick={() => setShowSuggestions(!showSuggestions)}
        >
          {showSuggestions ? <CloseIcon /> : <SearchIcon />}
        </div>

        {jwt && (
          <Autocomplete
            query={query}
            inputValue={inputValue}
            onSearch={onSearch}
            showSuggestions={showSuggestions}
            setInputValue={setInputValue}
            setInputValueQuietly={str => {
              if (inputRef.current) {
                inputRef.current.value = str;
              }
            }}
          />
        )}
      </div>
      <div
        className={classNames(scss.overlay, { [scss.on]: showSuggestions })}
      ></div>
    </div>
  );
};

export default SearchBox;
