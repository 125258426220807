import { createConnector } from "react-instantsearch-dom";

export const connectSearchState = createConnector({
  displayName: "SearchState",
  getProvidedProps(_, searchState) {
    return { searchState };
  },
  refine(_, __, nextSearchState) {
    return nextSearchState;
  },
  cleanUp(_, searchState) {
    const { ...nextSearchState } = searchState;

    return nextSearchState;
  },
});
