import { environment } from "../config/environment";
import { useSearchContext } from "../contexts";
import { cloudinaryUrlGenerator } from "./cloudinary-url-generator";

/**
 * getImageUrl determines whether to return the image_url property
 * from an Algolia record or a custom image url.
 *
 * @param {string} image_url
 * @param {string} content_type
 * @param {string} document_id
 * @return {*}  {string}
 */
export const getImageUrl = (
  image_url: string,
  content_type: string,
  document_id?: string,
  product_number?: string
): string => {
  const country = useSearchContext().country.toString();

  if (product_number && content_type === "product") {
    return cloudinaryUrlGenerator
      .image(`product-images/${product_number}`)
      .addTransformation("f_auto")
      .toURL();
  }

  if (
    environment.FEATURE_FLAGS.SRCX_531 &&
    document_id &&
    content_type === "taxonomy"
  ) {
    const api_id = document_id.replace("taxonomy-", "");
    return `${environment.ARTHREX_NEXT_API_HOST}/api/image?api_id=${api_id}&country=${country}`;
  }

  return image_url;
};
